import React, { useState } from 'react'
import { OrderFormSectionTitle } from '@res/styledComponents/index'
import { FlexContainer, XSpacing, YSpacing } from '@components/common'
import moment, { Moment } from 'moment-timezone'
import { TechHelpForm } from '@components/common'
import Flatpickr from 'react-flatpickr'
import LinkText from '@components/common/form/LinkText'
import Button from '@components/common/form/Button'
import { OrderableConfirmationModal } from '@components/common/modal'
import { colors } from '../../../../constants'
import { convertTimeIntoMomentTz } from '../../../../utils'
import {
  DinerProfile,
  Orderable,
  OrderableConfirmationParamaters,
} from '@types'

const DateFormat = 'LL'

interface BulkGenProposalsFromDinerProfileProps {
  accountExecutiveId: string
  confirmationModal: {
    show: (param: { text: string }) => Promise<boolean>
  }
  dinerProfile: DinerProfile
  hqLocaleMap: Record<number, string>
  generateBulkProposals: (
    hqLocaleMap: Record<number, string>,
    param: OrderableConfirmationParamaters[],
    callbackFn: (
      result: Orderable | null,
      index: number,
      error?: string,
    ) => void,
  ) => Orderable
  navigateToPreviousSection: () => void
  onHide: () => void
  backToProposalOptions: () => void
  setSkipToCustomMenus: () => void
  uri: string
}

const BulkGenProposalsFromDinerProfile = ({
  accountExecutiveId,
  confirmationModal,
  dinerProfile,
  hqLocaleMap,
  generateBulkProposals,
  navigateToPreviousSection,
  onHide,
  backToProposalOptions,
  setSkipToCustomMenus,
  uri,
}: BulkGenProposalsFromDinerProfileProps) => {
  const [selectedDates, setSelectedDates] = useState<Moment[]>([])
  const [genProposals, setGenProposals] = useState(false)
  const [genParams, setGenParams] = useState<OrderableConfirmationParamaters[]>(
    [],
  )

  const onDateSelection = (selectedDates: Date[]) => {
    const newDates: Moment[] = []
    selectedDates.forEach((d: Date) => {
      const dateInTz = convertTimeIntoMomentTz(d)
      newDates.push(dateInTz)
    })
    setSelectedDates(newDates)
  }

  const genBulkProposals = async () => {
    const dates = selectedDates
      .map((d) => d.format('dddd, MMMM Do'))
      .join('<br />')
    const confirmed = await confirmationModal.show({
      text: `<h1><strong>Are you sure you want to bulk create proposals for the following dates?</strong></h1> <br /> ${dates}?`,
    })
    if (confirmed) {
      const params: OrderableConfirmationParamaters[] = []
      selectedDates.forEach((d) => {
        params.push({
          date: d,
          dinerProfile,
          accountExecutiveId: accountExecutiveId,
        })
      })

      setGenProposals(true)
      setGenParams(params)
    } else {
      backToProposalOptions()
    }
  }

  return (
    <div>
      {genProposals && (
        <OrderableConfirmationModal
          orderable="Proposal"
          orderableType="proposals"
          params={genParams}
          hqLocaleMap={hqLocaleMap}
          coordinator={generateBulkProposals}
          uri={uri}
          errorLinks={[
            {
              message: '< Go Back to Select Another Past Order to Copy',
              clickHandler: () => setGenProposals(false),
            },
            {
              message: 'Create a Custom Proposal Instead >',
              clickHandler: setSkipToCustomMenus,
            },
          ]}
          successLinks={[
            {
              html: `${uri}/proposals-dashboard`,
              message: 'Go to Proposals Dashboard to View Proposals',
            },
          ]}
          onClose={onHide}
        />
      )}
      <OrderFormSectionTitle>
        <span>3</span>Generate Bulk Proposals
        <XSpacing width="25px" />
      </OrderFormSectionTitle>
      <YSpacing height="15px" />
      <div style={{ marginLeft: '30px' }}>
        <OrderFormSectionTitle>
          <p className="message" onClick={backToProposalOptions}>
            {'< Go Back To Generate Bulk Proposal Options'}
          </p>
          <XSpacing width="20px" />
          <p className="message" onClick={setSkipToCustomMenus}>
            {'Skip to Create Custom Proposal >'}
          </p>
        </OrderFormSectionTitle>
        <YSpacing height="15px" />
        <h1 className="text-red-500 font-bold underline">
          Generate Bulk Proposals without Menu Items
        </h1>
        <YSpacing height="30px" />
        <FlexContainer>
          <div className="relative w-3/5" style={{ zIndex: 99 }}>
            <Flatpickr
              height="400px"
              width="800px"
              options={{
                mode: 'multiple',
                altInput: true,
                altInputClass: 'hidden',
                inline: true,
                formatDate: (date: Date) => moment(date).format(DateFormat),
                onDayCreate: (dObj, dStr, fp, dayElem) => {
                  const date = dayElem.dateObj
                  const formattedDate = date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })

                  // Set a unique test id for each day in the "September 30, 2024" format
                  dayElem.setAttribute('data-testid', `${formattedDate}`)
                },
                onReady: (selectedDates, dateStr, instance) => {
                  const nextButton = instance.calendarContainer.querySelector(
                    '.flatpickr-next-month',
                  )
                  const prevButton = instance.calendarContainer.querySelector(
                    '.flatpickr-prev-month',
                  )

                  if (nextButton) {
                    nextButton.setAttribute('data-testid', 'next-month-button')
                  }
                  if (prevButton) {
                    prevButton.setAttribute('data-testid', 'prev-month-button')
                  }
                },
              }}
              onChange={(selectedDates) => onDateSelection(selectedDates)}
            />
          </div>
          <XSpacing width="20px" />
          <div className="flex flex-col">
            <h1 className="font-bold text-xl underline">How it Works:</h1>
            <YSpacing height="10px" />
            <ol className="ml-5 list-decimal">
              <li className="text-lg leading-relaxed">
                Select the dates you&apos;d like to generate proposals for
                without any menus
              </li>
              <li className="text-lg leading-relaxed">
                Click Generate Bulk Proposals
              </li>
              <li className="text-lg leading-relaxed">
                You will receive a confirmation once proposals are created & a
                quick link to direct you to your proposals dashboard to view the
                proposals you created to add your menu items
              </li>
            </ol>
          </div>
        </FlexContainer>
      </div>
      <YSpacing height="45px" />
      <FlexContainer justifyContent="flex-end">
        <Button
          testId="update-order-details"
          label={'〈 Update order details'}
          onClick={navigateToPreviousSection}
        />
        <XSpacing width="15px" />
        <Button
          testId="generate-bulk-proposals"
          label={'Generate Bulk Proposals 〉'}
          onClick={genBulkProposals}
          backgroundColor={colors.orange}
          disabled={selectedDates.length === 0}
        />
      </FlexContainer>
      <YSpacing height="20px" />
      <FlexContainer alignItems="center" justifyContent="flex-end">
        <TechHelpForm margin="0 30px 0 0" />
        <LinkText
          testId="cancel"
          label="Cancel"
          onClick={onHide}
          color={colors.violet}
        />
      </FlexContainer>
    </div>
  )
}

export default BulkGenProposalsFromDinerProfile

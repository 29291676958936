import { connect } from 'react-redux'

import { EditOrderModal } from '@components/order/edit'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'
import { HandleError, Validate } from '@coordinators/composed'
import { config } from 'hungry-core2'

const mapStateToProps = (state) => {
  const { lastLoggedInHq: headquarterId, permissions } = state.user || {}
  const userPermissions = permissions[headquarterId]
  const { mode, show, section } = state.editOrderModal
  const errors = state.errors.editOrderModal || {}
  const orderable = mode === 'edit' ? state.editOrder : state.newOrder
  const {
    invoiceContact,
    freeDeliveryPromoCode,
    isFreeDelivery,
    isPaid,
    isTaxExempt,
    orderDiscounts,
    orderTaxExempt: isOrderTaxExempt,
    orderTaxExemptReason,
    paidAmount,
    total,
  } = orderable

  let orderTaxExempt, orderTaxExemptCause
  if (isOrderTaxExempt) {
    orderTaxExempt = isOrderTaxExempt
    orderTaxExemptCause = orderTaxExemptReason
  } else if (invoiceContact && invoiceContact.purchaserTaxStatus !== null) {
    orderTaxExempt = !invoiceContact.purchaserTaxStatus
    orderTaxExemptCause = 'Invoice Contact'
  } else {
    orderTaxExempt = isTaxExempt
    orderTaxExemptCause = 'Account'
  }
  const paidWithoutChanges = isPaid && total === paidAmount

  const freeDeliveryWithoutPromo = isFreeDelivery && !freeDeliveryPromoCode
  const orderableType = 'Order'

  const { referralPartners, user, router } = state
  const port = router.location.port === 3000 ? 3001 : 3000
  const uri =
    config.admin_url +
    (config.admin_url === 'http://localhost' ? `:${port}` : '')

  const {
    headquarter: { headquarters },
  } = state
  const hqLocaleMap = headquarters.reduce((acc, hq) => {
    acc[hq.id] = hq.locale

    return acc
  }, {})

  return {
    orderable: {
      ...orderable,
      isFreeDelivery: freeDeliveryWithoutPromo,
      isPaid: paidWithoutChanges,
      isTaxExempt: orderTaxExempt,
      discounts: orderDiscounts || [],

      orderTaxExemptCause,
      orderableType,
      referralPartners,
    },
    headquarterId,
    user,
    userPermissions,
    section,
    hqLocaleMap,

    errors,
    show,
    uri,
  }
}

const mapDispatchToProps = () => {
  const {
    ChefService,
    OrderService,
    ReferralService,
    RestService,
    RouterService,
    SessionService,
    SettingsService,
    UIService,
  } = services
  const {
    AsyncLoadAccount,
    AsyncLoadAccounts,
    ConfirmPaymentMethod,
    DelayedUpdateOrder,
    HandleError: HandleError_,
    AsyncLoadChefs,
    AsyncLoadConcepts,
    LoadAccountExecutives,
    LoadChef,
    LoadOrders,
    LoadReferralPartners,
    LoadSettings,
    PredictServiceCosts,
    RetryOnTimeout,
    SaveAdhocAccount,
    SaveAdhocAddress,
    SaveAdhocContact,
    SaveAdhocPaymentMethod,
    SaveOrder,
    LoadCateringMenuItems,
    ValidatePickupTimes,
    LoadDinerProfile,
    LoadDinerProfiles,
  } = coordinators
  const {
    pContactKeys,
    pDefaultAccountFields,
    pOrderAttributesFromDinerProfile,
    pFindIdentical,
    pFindIdenticalPaymentMethod,
    pRemovedItemFromArray,
    pReplacedItemInArray,
    pStateToReduxOrder,
    pAccountSettingsFromAccount,
  } = presenters
  const {
    pResponseAccountExecutives,
    pRequestSaveAdhocAccount,
    pRequestSaveAdhocAddress,
    pRequestSaveAdhocContact,
    pRequestSaveAdhocPaymentMethod,
    pRequestOrdersFilter,
    pRequestOrderSupplyBreakdown,
    pRequestPredictServiceCosts,
    pRequestUpdateOrder,
    pRequestSettings,
    pRequestLoadConcepts,
    pResponseAllChefConcepts,
    pResponseSettings,
    pResponseChefs,
    pResponseChef,
    pResponseGeneric,
    pResponseOrder,
    pResponseOrders,
    pResponseOrderError,
    pResponseOrderSearchMenuItems,
  } = presenters.Api
  const { vAddress, vContact, vPaymentMethod } = presenters.Validation

  const {
    addCheftoChefs,
    addMenuItemToChefs,
    addMenuItemsToChefs,
    addServiceItemToChefs,
    addSnackPackToChefs,
    addVirtualItemToChefs,
    addVirtualKitToChefs,
    calculateDiscounts,
    calculateAll,
    getDeliveryFeePercentAndLimit,
    getEmptyChefs,
    reorderItemInChefs,
    reorderChef,
    replaceChefNoteInAddOnChefs,
    replaceOrderMenuItemInChefs,
    replaceOrderServiceItemInChefs,
    replaceOrderSnackPackInChefs,
    replaceOrderVirtualItemInChefs,
    replaceOrderVirtualKitInChefs,
    removeChefFromChefs,
    removeOrderMenuItemFromChefs,
    removeOrderServiceItemFromChefs,
    removeOrderSnackPackFromChefs,
    removeOrderVirtualItemFromChefs,
    removeOrderVirtualKitFromChefs,
    syncChildItems,
    updateTaxExemptStatus,
  } = OrderService
  const { clearEditOrderable: clearForm, getChefAmountMap } = OrderService
  const { clear: clearErrors, clearSingle } = UIService.Errors
  const clearError = clearSingle('editOrderModal')
  const { close } = UIService.EditOrder
  const flashInfoOptions = UIService.FlashMessage.displayInfoMessageOptions
  const flashWarningOptions =
    UIService.FlashMessage.displayWarningMessageOptions
  const confirmPaymentMethod = ConfirmPaymentMethod({ UIService })
  const loadAccountExecutives = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })
  const loadAccount = AsyncLoadAccount({
    RestService,
    pResponseGeneric,
    HandleError,
  })
  const loadAccounts = AsyncLoadAccounts({ RestService, pResponseGeneric })
  const loadConcepts = AsyncLoadConcepts({
    RestService,
    pResponseAllChefConcepts,
    pRequestLoadConcepts,
  })
  const loadConceptsSettings = LoadSettings({
    RestService,
    SettingsService,
    pRequestSettings,
    pResponseSettings,
    RetryOnTimeout,
    HandleError,
  })('concepts')
  const loadChefs = AsyncLoadChefs({
    ChefService,
    OrderService,
    RestService,
    pResponseChefs,
  })
  const loadChef = LoadChef({ RestService, pResponseChef })
  const loadOrders = LoadOrders({
    UIService,
    OrderService,
    RestService,
    pRequestOrdersFilter,
    pResponseOrders,
  })
  const loadReferralPartners = LoadReferralPartners({
    ReferralService,
    RestService,
    pResponseGeneric,
  })
  const delayedUpdateOrder = DelayedUpdateOrder({
    OrderService,
    UIService,
    pStateToReduxOrder,
  })
  const predictServiceCosts = PredictServiceCosts({
    RestService,
    pRequestPredictServiceCosts,
    pRequestOrderSupplyBreakdown,
    pResponseServiceCosts: pResponseGeneric,
  })
  const saveAccount = SaveAdhocAccount({
    RestService,
    pRequestSaveAdhocAccount,
    pResponseGeneric,
    HandleError,
  })
  const saveAddress = SaveAdhocAddress({
    RestService,
    pFindIdentical,
    pRequestSaveAdhocAddress,
    pResponseGeneric,
    HandleError,
  })
  const saveContact = SaveAdhocContact({
    RestService,
    pFindIdentical,
    pRequestSaveAdhocContact,
    pResponseGeneric,
    HandleError,
  })
  const saveOrder = SaveOrder({
    OrderService,
    RestService,
    RouterService,
    SessionService,
    UIService,
    pRequestUpdateOrder,
    pResponseGeneric,
    pResponseOrder,
    pResponseOrderError,
    HandleError: HandleError_,
  })
  const savePaymentMethod = SaveAdhocPaymentMethod({
    RestService,
    pFindIdenticalPaymentMethod,
    pRequestSaveAdhocPaymentMethod,
    pResponseGeneric,
    HandleError,
  })
  const validateAddress = Validate({
    validator: vAddress,
    namespace: 'editOrderModal',
  })
  const validateContact = Validate({
    validator: vContact,
    namespace: 'editOrderModal',
  })
  const validatePaymentMethod = Validate({
    validator: vPaymentMethod,
    namespace: 'editOrderModal',
  })
  const validateChefPickupTimes = ValidatePickupTimes({
    RestService,
    UIService,
    HandleError,
  })

  const loadFoodItems = LoadCateringMenuItems({
    RestService,
    pResponseOrderSearchMenuItems,
  })
  const loadDinerProfiles = LoadDinerProfiles({
    RestService,
    HandleError,
  })
  const loadDinerProfile = LoadDinerProfile({
    RestService,
    HandleError,
  })

  const { displayFailureMessage, displayWarningMessage } =
    UIService.FlashMessage

  return {
    addCheftoChefs,
    addMenuItemToChefs,
    addMenuItemsToChefs,
    addServiceItemToChefs,
    addSnackPackToChefs,
    addVirtualItemToChefs,
    addVirtualKitToChefs,
    getChefAmountMap,
    calculateAll,
    calculateDiscounts,
    clearError,
    clearErrors,
    clearForm,
    close,
    confirmPaymentMethod,
    delayedUpdateOrder,
    displayFailureMessage,
    displayWarningMessage,
    flashInfoOptions,
    flashWarningOptions,
    getDeliveryFeePercentAndLimit,
    getEmptyChefs,
    loadAccountExecutives,
    loadAccount,
    loadAccounts,
    loadDinerProfile,
    loadDinerProfiles,
    loadChefs,
    loadChef,
    loadFoodItems,
    loadConcepts,
    loadConceptsSettings,
    loadOrders,
    loadReferralPartners,
    pContactKeys,
    pDefaultAccountFields,
    pOrderAttributesFromDinerProfile,
    pRemovedItemFromArray,
    pReplacedItemInArray,
    pAccountSettingsFromAccount,
    predictServiceCosts,
    reorderItemInChefs,
    reorderChef,
    replaceChefNoteInAddOnChefs,
    replaceOrderMenuItemInChefs,
    replaceOrderServiceItemInChefs,
    replaceOrderSnackPackInChefs,
    replaceOrderVirtualItemInChefs,
    replaceOrderVirtualKitInChefs,
    removeOrderMenuItemFromChefs,
    removeOrderServiceItemFromChefs,
    removeOrderSnackPackFromChefs,
    removeOrderVirtualItemFromChefs,
    removeOrderVirtualKitFromChefs,
    removeChefFromChefs,
    saveAccount,
    saveAddress,
    saveContact,
    saveOrder,
    savePaymentMethod,
    syncChildItems,
    updateTaxExemptStatus,
    validateAddress,
    validateContact,
    validatePaymentMethod,
    validateChefPickupTimes,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditOrderModal)

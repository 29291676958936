import Moment from 'moment-timezone'

import { camelCaseify, formAdd, snakeCaseify } from '~/utils'

export const pResponseAccounts = (json) => {
  const accounts = json.map((j) => ({
    id: j.id,
    name: j.name,
    pin: j.pin,
    nameAndPin: `${j.name} (${j.pin})`,
  }))

  accounts.sort((a, b) => {
    const x = a.name && a.name.toLowerCase()
    const y = b.name && b.name.toLowerCase()

    return x < y ? -1 : x > y ? 1 : 0
  })

  return accounts
}

/* These two functions assume the enum of 0 - no permission, 1 - pending, 2 - approved */
const pPaymentPermissionToBool = (permission) => {
  switch (permission) {
    case 0:
      return false
    case 2:
      return true
    default:
      return false
  }
}

const pPaymentPermissionFromBool = (bool) => {
  switch (bool) {
    case true:
      return 2
    case false:
      return 0
    default:
      return bool
  }
}

export const pResponseAccount = (json) => {
  const account = camelCaseify(json)
  account.email = account.email || ''
  account.phoneNumber = account.phoneNumber || ''

  if (account.serviceDays) {
    account.serviceDays = account.serviceDays.split(',')
  } else {
    account.serviceDays = []
  }
  account.promoCodes.forEach((p) => {
    p.value = parseFloat(p.value || 0)
    if (p.kind === 'percentage') {
      p.value *= 100
    }
    p.value = p.value.toFixed(2)

    if (p.isDynamicCode) {
      p.description = 'Dynamic'
    } else if (p.isPercentage) {
      p.description = `%${p.value}`
    } else {
      p.description = `$${p.value}`
    }
    p.code = p.code || ''
    p.kind = p.kind || ''
    p.isActive = p.isActive ? 'Yes' : 'No'
    p.validFrom = p.validFrom
      ? Moment(p.validFrom, 'MM/DD/YYYY HH:mmaa').format('MM/DD/YYYY')
      : 'inactive'
    p.validTo = p.validTo
      ? Moment(p.validTo, 'MM/DD/YYYY HH:mmaa').format('MM/DD/YYYY')
      : 'inactive'
    p.isPublic = p.isPublic ? 'Public' : 'Private'
    p.redeemLimitPerUser == null ? 'None' : p.redeemLimitPerUser
  })
  account.netPayment = pPaymentPermissionToBool(account.netPayment)
  account.payByCheck = pPaymentPermissionToBool(account.payByCheck)
  account.payByACH = pPaymentPermissionToBool(account.payByAch)

  account.carbonNeutralContribution = parseFloat(
    account.carbonNeutralContribution || 0,
  )

  account.setUpCompleteByTime =
    account.setUpCompleteByTime && Moment(account.setUpCompleteByTime)
  account.doNotArriveBeforeTime =
    account.doNotArriveBeforeTime && Moment(account.doNotArriveBeforeTime)
  account.lastUpdatedAtGratuityAmount =
    account.lastUpdatedAtGratuityAmount &&
    Moment(account.lastUpdatedAtGratuityAmount).format('YYYY-MM-DD')
  account.lastUpdatedAtStaffingFee =
    account.lastUpdatedAtStaffingFee &&
    Moment(account.lastUpdatedAtStaffingFee).format('YYYY-MM-DD')
  account.lastUpdatedAtDeliveryAndServiceWeekendFee =
    account.lastUpdatedAtDeliveryAndServiceWeekendFee &&
    Moment(account.lastUpdatedAtDeliveryAndServiceWeekendFee).format(
      'YYYY-MM-DD',
    )
  account.lastUpdatedAtDeliveryAndServiceWeekdayFee =
    account.lastUpdatedAtDeliveryAndServiceWeekdayFee &&
    Moment(account.lastUpdatedAtDeliveryAndServiceWeekdayFee).format(
      'YYYY-MM-DD',
    )
  account.lastUpdatedAtDeliveryAndServiceHolidayFee =
    account.lastUpdatedAtDeliveryAndServiceHolidayFee &&
    Moment(account.lastUpdatedAtDeliveryAndServiceHolidayFee).format(
      'YYYY-MM-DD',
    )
  account.lastUpdatedAtSetUpCompleteByTime =
    account.lastUpdatedAtSetUpCompleteByTime &&
    Moment(account.lastUpdatedAtSetUpCompleteByTime).format('YYYY-MM-DD')
  account.lastUpdatedAtDoNotArriveBeforeTime =
    account.lastUpdatedAtDoNotArriveBeforeTime &&
    Moment(account.lastUpdatedAtDoNotArriveBeforeTime).format('YYYY-MM-DD')
  account.lastUpdatedAtClientNotes =
    account.lastUpdatedAtClientNotes &&
    Moment(account.lastUpdatedAtClientNotes).format('YYYY-MM-DD')
  account.lastUpdatedAtCuisinePreferences =
    account.lastUpdatedAtCuisinePreferences &&
    Moment(account.lastUpdatedAtCuisinePreferences).format('YYYY-MM-DD')
  account.lastUpdatedAtClientSuccessMetrics =
    account.lastUpdatedAtClientSuccessMetrics &&
    Moment(account.lastUpdatedAtClientSuccessMetrics).format('YYYY-MM-DD')
  account.firstOrderDate =
    account.firstOrderDate && Moment(account.firstOrderDate)

  return account
}

export const pResponseEditClientAccounting = (json) => {
  const accounting = {
    firstOrderDate: json.firstOrderDate && Moment(json.firstOrderDate),
    firstOrderDateType: json.firstOrderDateType,
    netPaymentDays: json.netPaymentDays,
    autoBatchActive: json.autoBatchActive,
    autoBatchPeriod: json.autoBatchPeriod,
    statementOfWork: json.statementOfWork,
    taxExemptionDocument: json.taxExemptionDocument,
    clientHunter: json.clientHunter,
    clientChampion: json.clientChampion,
    clientSDR: json.clientSDR,
    clientAutoInvoiceContacts: json.clientAutoInvoiceContacts,
    ogClientAutoInvoiceContacts: json.clientAutoInvoiceContacts,
    sowEffectiveDate: json.sowEffectiveDate,
    overdueBatchInvoiceRemindersActive: json.overdueBatchInvoiceRemindersActive,
  }

  if (json.defaultClientAutoInvoiceContact) {
    const contact = json.defaultClientAutoInvoiceContact
    accounting.selectedDefaultClientAutoInvoiceContact = {
      ...contact,
      // need to match accounting service schema with rails schema used to populate the contact options from rails
      phoneNumber: contact.phone,
    }
  }

  accounting.autoInvoiceBillingAddress = pGetAutoInvoiceBillingAddress(json)

  return accounting
}

export const pGetAutoInvoiceBillingAddress = (data) => {
  if (data.autoInvoiceBillingLine1) {
    let line1And2 = data.autoInvoiceBillingLine1
    if (data.autoInvoiceBillingLine2) {
      line1And2 = `${data.autoInvoiceBillingLine1}, ${data.autoInvoiceBillingLine2}`
    }
    const stateAndZip = `${data.autoInvoiceBillingState} ${data.autoInvoiceBillingZip}`

    return [line1And2, data.autoInvoiceBillingCity, stateAndZip].join(', ')
  } else {
    return null
  }
}

export const pResponseEditAccount = (json) => {
  const account = camelCaseify(json)
  account.email = account.email || ''
  account.phoneNumber = account.phoneNumber || ''
  account.addresses = account.addresses || []
  account.contacts = account.contacts || []
  account.paymentMethods = account.paymentMethods || []
  if (account.serviceDays) {
    account.serviceDays = account.serviceDays.split(',')
  } else {
    account.serviceDays = []
  }
  if (account.addresses.length === 0) {
    account.newAddresses = [{}]
  } else {
    account.newAddresses = []
  }
  if (account.contacts.length === 0) {
    account.newContacts = [{}]
  } else {
    account.newContacts = []
  }
  if (account.paymentMethods.length === 0) {
    account.newPaymentMethods = [{}]
  } else {
    account.newPaymentMethods = []
  }
  account.netPayment = pPaymentPermissionToBool(account.netPayment)
  account.payByCheck = pPaymentPermissionToBool(account.payByCheck)
  account.payByACH = pPaymentPermissionToBool(account.payByAch)

  account.carbonNeutralContribution = parseFloat(
    account.carbonNeutralContribution || 0,
  )

  account.setUpCompleteByTime =
    account.setUpCompleteByTime && Moment(account.setUpCompleteByTime)
  account.doNotArriveBeforeTime =
    account.doNotArriveBeforeTime && Moment(account.doNotArriveBeforeTime)
  account.lastUpdatedAtGratuityAmount =
    account.lastUpdatedAtGratuityAmount &&
    Moment(account.lastUpdatedAtGratuityAmount).format('YYYY-MM-DD')
  account.lastUpdatedAtStaffingFee =
    account.lastUpdatedAtStaffingFee &&
    Moment(account.lastUpdatedAtStaffingFee).format('YYYY-MM-DD')
  account.lastUpdatedAtDeliveryAndServiceWeekendFee =
    account.lastUpdatedAtDeliveryAndServiceWeekendFee &&
    Moment(account.lastUpdatedAtDeliveryAndServiceWeekendFee).format(
      'YYYY-MM-DD',
    )
  account.lastUpdatedAtDeliveryAndServiceWeekdayFee =
    account.lastUpdatedAtDeliveryAndServiceWeekdayFee &&
    Moment(account.lastUpdatedAtDeliveryAndServiceWeekdayFee).format(
      'YYYY-MM-DD',
    )
  account.lastUpdatedAtDeliveryAndServiceHolidayFee =
    account.lastUpdatedAtDeliveryAndServiceHolidayFee &&
    Moment(account.lastUpdatedAtDeliveryAndServiceHolidayFee).format(
      'YYYY-MM-DD',
    )
  account.lastUpdatedAtSetUpCompleteByTime =
    account.lastUpdatedAtSetUpCompleteByTime &&
    Moment(account.lastUpdatedAtSetUpCompleteByTime).format('YYYY-MM-DD')
  account.lastUpdatedAtDoNotArriveBeforeTime =
    account.lastUpdatedAtDoNotArriveBeforeTime &&
    Moment(account.lastUpdatedAtDoNotArriveBeforeTime).format('YYYY-MM-DD')
  account.lastUpdatedAtClientNotes =
    account.lastUpdatedAtClientNotes &&
    Moment(account.lastUpdatedAtClientNotes).format('YYYY-MM-DD')
  account.lastUpdatedAtCuisinePreferences =
    account.lastUpdatedAtCuisinePreferences &&
    Moment(account.lastUpdatedAtCuisinePreferences).format('YYYY-MM-DD')
  account.lastUpdatedAtClientSuccessMetrics =
    account.lastUpdatedAtClientSuccessMetrics &&
    Moment(account.lastUpdatedAtClientSuccessMetrics).format('YYYY-MM-DD')
  account.firstOrderDate =
    account.firstOrderDate && Moment(account.firstOrderDate)

  return account
}

export const pResponseCreateAccountFromHubspot = (hubspotData) => {
  const account = {}
  account.phoneNumber = hubspotData.phone || ''
  account.email = hubspotData.email || ''
  account.name = hubspotData.name || ''
  account.domain = hubspotData.domain || ''
  if (hubspotData.contacts.length > 0) {
    account.newContacts = []
    hubspotData.contacts.forEach((c) => {
      account.newContacts.push(c)
    })
  }

  return account
}

const pRequestAccountReferral = ({
  deletedReferralPartners,
  id: accountId,
  pastReferrals,
  referredBy,
  selectedReferralPartner,
}) => {
  const attr = []
  if (selectedReferralPartner || deletedReferralPartners.length !== 0) {
    const { id: contactId } = selectedReferralPartner
    const updateReferredBy = selectedReferralPartner
      ? pastReferrals.filter(
          (p) => p.contactId === selectedReferralPartner.id,
        )[0]
      : null
    const updateRefId = updateReferredBy ? updateReferredBy.id : null
    let destroy = false
    if (deletedReferralPartners[0]) {
      const { id: dId } = deletedReferralPartners[0]
      if (dId === contactId) {
        destroy = true
      }
    }
    if (referredBy && selectedReferralPartner.id !== referredBy.contactId) {
      attr.push({
        id: referredBy.id,
        is_active: false,
      })
    }
    attr.push({
      id: updateRefId,
      referrable_id: accountId,
      referrable_type: 'Account',
      contact_id: contactId,
      is_active: !destroy,
      _destroy: destroy,
    })
  }

  return attr
}

export const pRequestUpdateAccount = (data) => {
  const req = {}

  formAdd(data, req, 'avgPricePerPerson', 'avg_price_per_person')
  formAdd(data, req, 'avgPricePerPersonType', 'avg_price_per_person_type')
  formAdd(data, req, 'callOnArrival', 'call_on_arrival')
  formAdd(data, req, 'defaultBillingAddress.id', 'default_billing_address_id')
  formAdd(data, req, 'estimatedMonthlyValue', 'estimated_monthly_value')
  formAdd(data, req, 'gratuity', 'gratuity')
  formAdd(data, req, 'gratuityType', 'gratuity_type')
  formAdd(data, req, 'needStaffAndServe', 'need_staff_and_serve')
  formAdd(data, req, 'cateringStaffCount', 'catering_staff_count')
  formAdd(data, req, 'cateringStaffHours', 'catering_staff_hours')
  formAdd(data, req, 'cateringStaffRate', 'catering_staff_rate')
  formAdd(data, req, 'cateringStaffFee', 'catering_staff_fee')
  formAdd(
    data,
    req,
    'deliveryAndServiceFeePercentWeekend',
    'delivery_and_service_fee_percent_weekend',
  )
  formAdd(
    data,
    req,
    'deliveryAndServiceFeePercentWeekday',
    'delivery_and_service_fee_percent_weekday',
  )
  formAdd(
    data,
    req,
    'deliveryAndServiceFeePercentHoliday',
    'delivery_and_service_fee_percent_holiday',
  )
  formAdd(
    data,
    req,
    'deliveryAndServiceFeeCapWeekend',
    'delivery_and_service_fee_cap_weekend',
  )
  formAdd(
    data,
    req,
    'deliveryAndServiceFeeCapWeekday',
    'delivery_and_service_fee_cap_weekday',
  )
  formAdd(
    data,
    req,
    'deliveryAndServiceFeeCapHoliday',
    'delivery_and_service_fee_cap_holiday',
  )
  formAdd(data, req, 'incumbent', 'incumbent')
  formAdd(data, req, 'clientNotes', 'client_notes')
  formAdd(data, req, 'cuisinePreferences', 'cuisine_preferences')
  formAdd(data, req, 'clientSuccessMetrics', 'client_success_metrics')
  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'isTaxExempt', 'is_tax_exempt')
  formAdd(data, req, 'hideFromMapView', 'hide_from_map_view')
  formAdd(data, req, 'name', 'name')
  formAdd(data, req, 'needsServingUtensils', 'needs_serving_utensils')
  formAdd(data, req, 'needsUtensils', 'needs_utensils')
  formAdd(data, req, 'numEmployees', 'num_employees')
  formAdd(data, req, 'expectedOrderingHeadcount', 'expected_ordering_headcount')
  formAdd(data, req, 'parkingInstructions', 'parking_instructions')
  formAdd(data, req, 'vendorId', 'vendor_id')
  formAdd(data, req, 'hunterId', 'hunter_id')
  formAdd(data, req, 'championId', 'champion_id')
  formAdd(data, req, 'autoBatchPeriod', 'batch_period')
  formAdd(data, req, 'netPaymentDays', 'due_date_offset')
  formAdd(data, req, 'removeDueDateFromInv', 'remove_due_date_from_inv')
  formAdd(data, req, 'netPayment', 'net_payment', (v) =>
    pPaymentPermissionFromBool(v),
  )
  formAdd(data, req, 'logoUrl', 'logo_url', (v) =>
    pPaymentPermissionFromBool(v),
  )
  formAdd(data, req, 'orderingCadence', 'ordering_cadence')
  formAdd(data, req, 'serviceDays', 'service_days', (d) => d.join(','))
  formAdd(data, req, 'carbonNeutral', 'carbon_neutral')
  // formAdd(
  //   data,
  //   req,
  //   'carbonNeutralContribution',
  //   'carbon_neutral_contribution',
  //   (v) => (data.carbonNeutral ? v : 0),
  // )
  formAdd(data, req, 'domain', 'domain')
  if (data.hubspotId) {
    formAdd(data, req, 'hubspotId', 'hubspot_id')
  }
  if (data.hubspotIdChanged) {
    req.hubspot_id_changed_at = Moment()
  }
  formAdd(
    data,
    req,
    'orderContactAutoInvoiceEmails',
    'order_contact_auto_invoice_emails',
  )
  formAdd(
    data,
    req,
    'orderContactLatePaymentReminders',
    'order_contact_late_payment_reminders',
  )
  formAdd(
    data,
    req,
    'invoiceContactAutoInvoiceEmails',
    'invoice_contact_auto_invoice_emails',
  )
  formAdd(
    data,
    req,
    'invoiceContactLatePaymentReminders',
    'invoice_contact_late_payment_reminders',
  )
  formAdd(data, req, 'payByMethod', 'pay_by_method')

  if (data.setUpCompleteByTime) {
    formAdd(data, req, 'setUpCompleteByTime', 'set_up_complete_by_time', (v) =>
      v.toDate(),
    )
  }
  if (data.doNotArriveBeforeTime) {
    formAdd(
      data,
      req,
      'doNotArriveBeforeTime',
      'do_not_arrive_before_time',
      (v) => v.toDate(),
    )
  }
  if (data.firstOrderDate) {
    formAdd(data, req, 'firstOrderDate', 'first_order_date', (v) => v.toDate())
  }

  req.referrals_attributes = pRequestAccountReferral(data)
  if (data.deletedParentAccount) {
    req.child_relationship_attributes = {
      id: data.childRelationshipId,
      _destroy: true,
    }
  } else if (data.parentAccount && data.parentAccount !== 'N/A') {
    req.child_relationship_attributes = {
      parent_account_id: data.parentAccount.id,
      child_account_id: data.id,
    }
    if (data.originalParentAccount) {
      req.child_relationship_attributes.id = data.childRelationshipId
    }
  }
  // Addresses
  req.addresses_attributes = []
  data.newAddresses.forEach((a) => {
    // skip empty
    if (Object.keys(a).length === 0) {
      return
    }

    const address = {}
    formAdd(a, address, 'id', 'id')
    formAdd(a, address, 'line1', 'line1')
    formAdd(a, address, 'line2', 'line2')
    formAdd(a, address, 'city', 'city')
    formAdd(a, address, 'state', 'state')
    formAdd(a, address, 'zip', 'zip')
    formAdd(a, address, 'buildingInstructions', 'building_instructions')
    formAdd(a, address, 'updatedBy', 'updated_by')

    req.addresses_attributes.push(address)
  })
  data.deletedAddresses.forEach((a) => {
    req.addresses_attributes.push({
      id: a.id,
      _destroy: true,
    })
  })

  // Contacts

  req.contacts_attributes = []
  req.account_contacts_attributes = []
  const accountContactMap = {}
  data.newContacts.forEach((c) => {
    // skip empty
    if (Object.keys(c).length === 0) {
      return
    }

    const contact = {
      purchaser_tax_status_attributes: {},
    }
    formAdd(c, contact, 'id', 'id')
    formAdd(c, contact, 'firstName', 'first_name')
    formAdd(c, contact, 'lastName', 'last_name')
    formAdd(c, contact, 'title', 'title')
    formAdd(c, contact, 'phoneNumber', 'phone_number')
    formAdd(c, contact, 'email', 'email')
    formAdd(c, contact, 'isReferralPartner', 'is_referral_partner')
    formAdd(
      c,
      contact.purchaser_tax_status_attributes,
      'purchaserTaxStatus',
      'taxable',
    )
    formAdd(c, contact, 'updatedBy', 'updated_by')

    if (c.id && c.existing) {
      req.account_contacts_attributes.push({
        account_id: data.id,
        contact_id: c.id,
        contact_attributes: contact,
      })
    } else if (!c.existing) {
      req.contacts_attributes.push(contact)
    }
  })
  data.accountContacts &&
    data.accountContacts.forEach((c) => {
      accountContactMap[c.contactId] = c.id
    })
  data.deletedContacts.forEach((c) => {
    req.account_contacts_attributes.push({
      id: accountContactMap[c.id],
      _destroy: true,
    })
  })
  // Payment Methods

  req.payment_methods_attributes = []
  data.newPaymentMethods.forEach((p) => {
    // skip empty
    if (Object.keys(p).length === 0) {
      return
    }

    const paymentMethod = {
      billing_address_attributes: {},
    }
    formAdd(p, paymentMethod, 'id', 'id')
    formAdd(p, paymentMethod, 'cardholderName', 'cardholder_name')
    formAdd(p, paymentMethod, 'cardType', 'card_type')
    formAdd(p, paymentMethod, 'expirationDate', 'expiration_date')
    formAdd(p, paymentMethod, 'last4', 'last4')
    formAdd(p, paymentMethod, 'nonce', 'nonce')
    const { billingAddress } = p
    formAdd(
      billingAddress,
      paymentMethod.billing_address_attributes,
      'id',
      'id',
    )
    formAdd(
      billingAddress,
      paymentMethod.billing_address_attributes,
      'line1',
      'line1',
    )
    formAdd(
      billingAddress,
      paymentMethod.billing_address_attributes,
      'line2',
      'line2',
    )
    formAdd(
      billingAddress,
      paymentMethod.billing_address_attributes,
      'city',
      'city',
    )
    formAdd(
      billingAddress,
      paymentMethod.billing_address_attributes,
      'zip',
      'zip',
    )
    formAdd(
      billingAddress,
      paymentMethod.billing_address_attributes,
      'state',
      'state',
    )
    formAdd(
      billingAddress,
      paymentMethod.billing_address_attributes,
      'buildingInstructions',
      'building_instructions',
    )

    req.payment_methods_attributes.push(paymentMethod)
  })
  data.deletedPaymentMethods.forEach((p) => {
    req.payment_methods_attributes.push({
      id: p.id,
      payable_method_id: null,
      payable_method_type: null,
    })
  })

  //Marketplace Users
  req.marketplace_user_accounts_attributes = []
  data.newMarketplaceUsers.forEach((u) => {
    if (Object.keys(u).length === 0) {
      return
    }
    const user = {}
    formAdd(data, user, 'id', 'account_id')
    formAdd(u, user, 'id', 'marketplace_user_id')
    req.marketplace_user_accounts_attributes.push(user)
  })
  data.deletedMarketplaceUsers.forEach((u) => {
    const mktplaceUsrAcct = u.marketplaceUserAccounts.find(
      (acct) => acct.accountId === data.id,
    )
    req.marketplace_user_accounts_attributes.push({
      id: mktplaceUsrAcct.id,
      _destroy: true,
    })
  })

  return { account: req }
}

export const pRequestUpsertAccountingClient = (data) => {
  const update = {
    id: data.id,
    pin: data.pin,
    name: data.name,
    netPaymentDays:
      data.netPaymentDays == null ? null : parseFloat(data.netPaymentDays),
    averagePricePerPerson:
      data.avgPricePerPerson == null
        ? null
        : parseFloat(data.avgPricePerPerson),
    averagePricePerPersonType: data.avgPricePerPersonType,
    numEmployees: data.numEmployees,
    expectedOrderingHeadcount:
      data.expectedOrderingHeadcount == null
        ? null
        : parseFloat(data.expectedOrderingHeadcount),
    estimatedMonthlyValue:
      data.estimatedMonthlyValue == null
        ? null
        : parseFloat(data.estimatedMonthlyValue),
    serviceDays: data.serviceDays ? data.serviceDays.join(',') : '',
    orderingCadence: data.orderingCadence,
    incumbent: data.incumbent,
    removeDueDateFromInv: data.removeDueDateFromInv,
    autoBatchActive: data.autoBatchActive,
    autoBatchPeriod: data.autoBatchPeriod,
    firstOrderDate: data.firstOrderDate && data.firstOrderDate.format(),
    firstOrderDateType: data.firstOrderDateType,
    statementOfWork: data.statementOfWork,
    taxExemptionDocument: data.taxExemptionDocument,
    clientHunter: data.clientHunter,
    clientChampion: data.clientChampion,
    clientSDR: data.clientSDR,
    hasCCs: data.hasCCs,
    payByMethod: data.payByMethod,
    sowEffectiveDate: data.sowEffectiveDate,
    latePaymentEmailByInvoiceContact: data.invoiceContactLatePaymentReminders,
    latePaymentEmailByOrderContact: data.orderContactLatePaymentReminders,
    autoInvoiceEmailByInvoiceContact: data.invoiceContactAutoInvoiceEmails,
    autoInvoiceEmailByOrderContact: data.orderContactAutoInvoiceEmails,
    overdueBatchInvoiceRemindersActive: data.overdueBatchInvoiceRemindersActive,
    autoInvoiceBillingLine1: data.autoInvoiceBillingLine1,
    autoInvoiceBillingLine2: data.autoInvoiceBillingLine2,
    autoInvoiceBillingCity: data.autoInvoiceBillingCity,
    autoInvoiceBillingState: data.autoInvoiceBillingState,
    autoInvoiceBillingZip: data.autoInvoiceBillingZip,
    hubspotId: data.hubspotId ?? null,
  }
  if (data.hubspotIdChanged) {
    update.hubspotIdChangedAt = Moment()
  }

  if (data.selectedDefaultClientAutoInvoiceContact) {
    const invContact = data.selectedDefaultClientAutoInvoiceContact
    update.defaultClientAutoInvoiceContact = {
      id: invContact.id,
      name: invContact.name,
      email: invContact.email,
      phone: invContact.phoneNumber,
    }
  }

  const { clientAutoInvoiceContacts, ogClientAutoInvoiceContacts } = data
  const addedAutoInvContacts = clientAutoInvoiceContacts.filter(
    (c) => !ogClientAutoInvoiceContacts.find((og) => og.id === c.id),
  )
  if (addedAutoInvContacts.length > 0) {
    update.clientAutoInvoiceContacts = addedAutoInvContacts
  }
  const remAutoInvContacts = ogClientAutoInvoiceContacts.filter(
    (c) => !clientAutoInvoiceContacts.find((og) => og.id === c.id),
  )
  if (remAutoInvContacts.length > 0) {
    update.remClientAutoInvoiceContacts = remAutoInvContacts.map((c) => ({
      uid: c.uid,
    }))
  }

  return update
}

export const pContactToAccountingContact = (contact) => {
  return {
    id: contact.id,
    email: contact.email,
    name: contact.name,
    phone: contact.phoneNumber,
  }
}

export const pRequestUpdatePreferenceProfile = (data) => {
  const { filters, preferences } = data
  const req = {
    filters: snakeCaseify(filters),
    preferences: snakeCaseify(preferences),
  }
  formAdd(data, req, 'Id', '_id')
  formAdd(data, req, 'handPickedRecommendations', 'hand_picked_recommendations')
  formAdd(data, req, 'recentlyOrderedConcepts', 'recently_ordered_concepts')
  formAdd(data, req, 'recentlyRejectedConcepts', 'recently_rejected_concepts')

  return req
}

export const pRequestUpdateAccountPromoCodes = (data) => {
  const req = {}
  req.account_promo_codes_attributes = []

  formAdd(data, req, 'id', 'id')
  data.promoCodes &&
    data.promoCodes.forEach((code) => {
      const params = snakeCaseify(code)
      if ((code.id && code._destroy) || code.promoCodeId) {
        req.account_promo_codes_attributes.push(params)
      }
    })

  return { account: req }
}

export const pResponseInvoiceAccount = (json) => {
  const account = camelCaseify(json)
  account.email = account.email || ''
  account.phoneNumber = account.phoneNumber || ''

  const familialAccounts = {}
  const familialContacts = {}
  const familialAddresses = {}

  familialAccounts[account.id] = account
  account.contacts &&
    account.contacts.forEach((contact) => {
      familialContacts[contact.id] = contact
    })
  account.addresses &&
    account.addresses.forEach((address) => {
      familialAddresses[address.id] = address
    })

  //children
  account.childAccounts &&
    account.childAccounts.forEach((child) => {
      familialAccounts[child.id] = child
      child.contacts &&
        child.contacts.forEach((contact) => {
          familialContacts[contact.id] = contact
        })
      child.addresses &&
        child.addresses.forEach((address) => {
          familialAddresses[address.id] = address
        })
    })

  //parent (& siblings)
  const { parentAccount } = account
  if (parentAccount && parentAccount.id) {
    familialAccounts[parentAccount.id] = parentAccount
    parentAccount.contacts &&
      parentAccount.contacts.forEach((contact) => {
        familialContacts[contact.id] = contact
      })
    parentAccount.addresses &&
      parentAccount.addresses.forEach((address) => {
        familialAddresses[address.id] = address
      })

    //siblings
    parentAccount.childAccounts &&
      parentAccount.childAccounts.forEach((sibling) => {
        familialAccounts[sibling.id] = sibling
        sibling.contacts &&
          sibling.contacts.forEach((contact) => {
            familialContacts[contact.id] = contact
          })
        sibling.addresses &&
          sibling.addresses.forEach((address) => {
            familialAddresses[address.id] = address
          })
      })
  }

  account.familialAccounts = familialAccounts
  account.familialContacts = familialContacts
  account.familialAddresses = familialAddresses

  return account
}

export const pResponseInvoiceAccounts = (json) => {
  const accounts = json.map((j) => pResponseInvoiceAccount(j))

  return accounts
}

export const pResponseAccountStats = (json) => camelCaseify(json)

export const pResponseAccountMpUsers = (json) => {
  const mpUsers = camelCaseify(json)

  return mpUsers
    .map((mpUser) => {
      mpUser.name =
        `${mpUser.firstName} ${mpUser.lastName} (${mpUser.email})`.trim()

      return mpUser
    })
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
}

export const pResponseHubspotAccounts = (json) => {
  return json.map((j) => camelCaseify(j))
}

export const pRequestBulkUpdateHubspotAccounts = (data) => {
  const req = []

  data.forEach((account) => {
    const updateAttributes = {}
    formAdd(account, updateAttributes, 'id', 'id')
    formAdd(account, updateAttributes, 'domain', 'domain')
    formAdd(account, updateAttributes, 'hubspotId', 'hubspot_id')

    const accUpdate = {}
    formAdd({ updateAttributes }, accUpdate, 'updateAttributes', 'account')

    req.push(accUpdate)
  })

  return { accounts: req }
}

import Moment from 'moment-timezone'

import { camelCaseify, formAdd, snakeCaseify, sortByAttribute } from '~/utils'
import { DEFAULT_GRATUITY_TYPE_PERCENT } from '../../../constants'
import {
  combineDateTime,
  createChefOrderInstruction,
  createOrderMenuItems,
  createOrderServiceItem,
  createOrderVirtualItem,
  organizeChildOrderItems,
} from '@presenters/api/order'

const discountKinds = [
  'cancellation',
  'discount',
  'hungry_guarantee',
  'marketing',
  'tasting',
  'sales code',
  'partnership',
  'budget',
]

export const pResponseProposals = (json) => {
  const proposals = camelCaseify(json)
  proposals.forEach((proposal) => {
    proposal.chefName = proposal.chefsString
    proposal.dateStr = Moment(proposal.clientSetUpTime).format(
      'MM-DD-YY @ h:mma',
    )
    proposal.dateMoment = Moment(proposal.clientSetUpTime)
    proposal.total = parseFloat(proposal.total).toFixed(2)
  })

  return proposals
}

export const pResponseProposal = (json) => {
  const proposal = camelCaseify(json)

  proposal.setUpStyle = proposal.setUpStyle || 'None Selected'

  proposal.eventDate = proposal.eventDate && Moment(proposal.eventDate)
  proposal.clientSetUpTime =
    proposal.clientSetUpTime && Moment(proposal.clientSetUpTime)
  proposal.hqArrivalTime =
    proposal.hqArrivalTime && Moment(proposal.hqArrivalTime)
  proposal.hqDepartureTime =
    proposal.hqDepartureTime && Moment(proposal.hqDepartureTime)
  proposal.kitchenArrivalTime =
    proposal.kitchenArrivalTime && Moment(proposal.kitchenArrivalTime)
  proposal.kitchenDepartureTime =
    proposal.kitchenDepartureTime && Moment(proposal.kitchenDepartureTime)
  proposal.clientArrivalTime =
    proposal.clientArrivalTime && Moment(proposal.clientArrivalTime)
  proposal.chargeDate = proposal.chargeDate && Moment(proposal.chargeDate)

  proposal.eventDateStr = proposal.clientSetUpTime.format(
    'dddd, MMMM Do YYYY, h:mm:ss a',
  )
  proposal.dateStr =
    proposal.clientSetUpTime && proposal.clientSetUpTime.format('LLLL')
  proposal.hqArrivalTimeStr =
    proposal.hqArrivalTime && proposal.hqArrivalTime.format('h:mm A')
  proposal.hqDepartureTimeStr =
    proposal.hqDepartureTime && proposal.hqDepartureTime.format('h:mm A')
  proposal.kitchenArrivalTimeStr =
    proposal.kitchenArrivalTime && proposal.kitchenArrivalTime.format('h:mm A')
  proposal.kitchenDepartureTimeStr =
    proposal.kitchenDepartureTime &&
    proposal.kitchenDepartureTime.format('h:mm A')
  proposal.clientArrivalTimeStr =
    proposal.clientArrivalTime && proposal.clientArrivalTime.format('h:mm A')
  proposal.clientSetUpTimeStr =
    proposal.clientSetUpTime && proposal.clientSetUpTime.format('h:mm A')
  proposal.chargeDateStr =
    proposal.chargeDate && proposal.chargeDate.format('LLLL')
  proposal.clientLastNotified =
    (proposal.clientLastNotified &&
      Moment(proposal.clientLastNotified).format(
        'dddd, MMMM Do YYYY, h:mm:ss a',
      )) ||
    'Never'
  proposal.isEventStr = proposal.isEvent ? 'Yes' : 'No'

  const cleanedDiscounts = []
  let freeDeliveryPromoCode = false
  proposal.statuses = proposal.proposalStatuses || []
  proposal.discounts = proposal.orderDiscounts || []
  proposal.discounts.forEach((d) => {
    if (d.kind === 'tax_exempt') {
      return
    } else if (d.kind === 'free_delivery') {
      freeDeliveryPromoCode = true
    }
    d.value = parseFloat(d.value)
    if (d.isPercentage) {
      d.description = `%${Math.trunc(d.value * 100)} off ${
        d.code !== null ? `(${d.code})` : ''
      }`
    } else {
      d.description = `$${d.value.toFixed(2)} off ${
        d.code !== null ? `(${d.code})` : ''
      }`
    }
    if (!discountKinds.includes(d.kind)) {
      cleanedDiscounts.push(d)
    } else {
      proposal.discount = d
    }
  })
  proposal.discounts = cleanedDiscounts
  proposal.totalDiscount = parseFloat(proposal.totalDiscount || 0).toFixed(2)

  // service fees
  proposal.deliveryFee = parseFloat(proposal.deliveryFee || 0).toFixed(2)
  proposal.cleanupFee = parseFloat(proposal.cleanupFee || 0).toFixed(2)
  proposal.staffingFee = parseFloat(proposal.staffingFee || 0).toFixed(2)
  proposal.numberOfStaff = parseFloat(proposal.numberOfStaff || 0).toFixed(2)
  proposal.staffingHours = parseFloat(proposal.staffingHours || 0).toFixed(2)

  const pm = proposal.paymentMethod
  proposal.paymentMethodStr =
    pm &&
    `${pm.cardholderName || 'Name'} - ${pm.cardType || 'Card Type'} - Last 4${
      pm.last4 && ' *' + pm.last4
    } - ${pm.expirationDate || 'Exp Date'}`

  proposal.contactStr =
    (proposal.contact && proposal.contact.formattedDescription) ||
    'None Selected'
  proposal.invoiceContactStr =
    (proposal.invoiceContact && proposal.invoiceContact.formattedDescription) ||
    'None Selected'
  proposal.receiptContactStr =
    (proposal.receiptContact && proposal.receiptContact.formattedDescription) ||
    'None Selected'

  proposal.paidAmount = parseFloat(proposal.paidAmount || 0).toFixed(2)
  proposal.subtotal = parseFloat(proposal.subtotal || 0).toFixed(2)
  proposal.serviceFee =
    proposal.isFreeDelivery && !freeDeliveryPromoCode
      ? parseFloat(0).toFixed(2)
      : parseFloat(proposal.serviceFee || 0).toFixed(2)
  proposal.carbonNeutralContribution = parseFloat(
    proposal.carbonNeutralContribution || 0,
  ).toFixed(2)
  proposal.tax = parseFloat(proposal.tax || 0).toFixed(2)
  proposal.tip = parseFloat(proposal.tip || 0).toFixed(2)
  proposal.total = parseFloat(proposal.total || 0).toFixed(2)
  proposal.chefAmount = parseFloat(proposal.chefAmount || 0.0).toFixed(2)

  proposal.audits.forEach((a) => {
    a.createdAt = Moment(a.createdAt).format('MM-DD-YY @ h:mma')
    a.auditedChanges = a.auditedChanges && JSON.stringify(a.auditedChanges)
  })
  proposal.transactions.forEach((t) => {
    t.createdAt = Moment(t.createdAt).format('MM-DD-YY @ h:mma')
    t.amount = parseFloat(t.amount || 0).toFixed(2)
    t.serviceFeeAmount = parseFloat(t.serviceFeeAmount || 0).toFixed(2)
  })
  proposal.chefs.forEach((c) => {
    c.name = [c.firstName, c.lastName].filter((o) => o).join(' ')
  })

  const requiredActions = proposal.requiredActions || []
  proposal.needAlertClient = requiredActions.includes('Alert Client')
  proposal.validOrder = proposal.orderableErrors.length !== 0 || proposal.order

  return proposal
}

export const pResponseEditProposal = ({
  orderableJson,
  calculateDiscounts,
  getDeliveryFeePercentAndLimit,
}) => {
  const proposal = camelCaseify(orderableJson)

  proposal.serviceNotes = proposal.servicesInstructions
  proposal.setUpStyle = proposal.setUpStyle || ''
  proposal.chargeDate = proposal.chargeDate && Moment(proposal.chargeDate)
  proposal.clientSetUpTime =
    proposal.clientSetUpTime && Moment(proposal.clientSetUpTime)
  proposal.clientDoNotArriveBeforeTime =
    proposal.clientDoNotArriveBeforeTime &&
    Moment(proposal.clientDoNotArriveBeforeTime)
  proposal.cateringContact = proposal.contact
  proposal.chefAmount = parseFloat(proposal.chefAmount || 0)
  proposal.chefStatuses = {} // doesnt exist on proposal but order modal expects them
  proposal.chefPayouts = {} // doesnt exist on proposal but order modal expects them
  proposal.adjustedChefPayouts = [] // doesnt exist on proposal but order modal expects them
  proposal.serviceFee = parseFloat(proposal.serviceFee || 0)
  proposal.carbonNeutralContribution = parseFloat(
    proposal.carbonNeutralContribution || 0,
  )
  proposal.predictedServiceCosts = parseFloat(
    proposal.predictedServiceCosts || 0,
  )
  proposal.subtotal = parseFloat(proposal.subtotal || 0)
  proposal.tax = parseFloat(proposal.tax || 0)
  proposal.tip = parseFloat(proposal.tip || 0)
  proposal.total = parseFloat(proposal.total || 0)
  let lastStatus
  ;(proposal.proposalStatuses || []).forEach((status) => {
    if (
      !lastStatus ||
      Moment(status.updatedAt).isAfter(Moment(lastStatus.updatedAt))
    ) {
      lastStatus = status
    }
  })
  proposal.status = lastStatus ? lastStatus.code : ''

  const cleanedDiscounts = []
  // Pull out all the mp discounts with promo codes, then set the main discount to the sales code from admin
  proposal.orderDiscounts.forEach((d) => {
    if (!discountKinds.includes(d.kind)) {
      cleanedDiscounts.push({
        ...d,
        amount: d.isPercentage
          ? parseFloat(d.value) * 100
          : parseFloat(d.value),
        id: d.id,
        type: d.isPercentage ? 'percent' : 'flat',
        reason: d.reason,
        kind: d.kind,
      })
    }
    if (d.kind === 'free_delivery') {
      proposal.freeDeliveryPromoCode = true
    }
  })
  if ((proposal.orderDiscounts || []).length > 0) {
    const d = proposal.orderDiscounts.find((discount) =>
      discountKinds.includes(discount.kind),
    )
    const discountAmount = d.isPercentage
      ? parseFloat(d.value) * 100
      : parseFloat(d.value)
    const discountType = d.isPercentage ? 'percent' : 'flat'
    if (d) {
      proposal.discount = {
        amount: discountAmount,
        id: d.id,
        type: discountType,
        reason: d.reason,
        kind: d.kind,
      }
    }

    proposal.discountKind = d.kind
    proposal.discountReason = d.reason
    proposal.discountAmount = discountAmount
    proposal.discountType = discountType
  }
  proposal.orderDiscounts = cleanedDiscounts

  proposal.originalMenuItemIds = proposal.proposalMenuItems.map((i) => i.id)
  proposal.originalServiceItemIds = proposal.proposalServiceItems.map(
    (i) => i.id,
  )
  proposal.originalVirtualItemIds = proposal.proposalVirtualItems.map(
    (i) => i.id,
  )

  // Clean proposal menu items
  proposal.proposalMenuItems.forEach((i) => {
    i.chefPrice = parseFloat(i.cost)
    i.price = parseFloat(i.price)
    if (!i.displayOrder) {
      const displayOrders = proposal.proposalMenuItems.map(
        (i) => i.displayOrder || 0,
      )
      const maxDisplayOrder = Math.max(...displayOrders)
      i.displayOrder = maxDisplayOrder + 1
    } else {
      i.displayOrder = parseFloat(i.displayOrder)
    }
  })
  proposal.proposalMenuItems = organizeChildOrderItems(
    proposal.proposalMenuItems,
  )
  sortByAttribute(proposal.proposalMenuItems, 'displayOrder')

  // Clean order service items
  proposal.proposalServiceItems.forEach((i) => {
    i.cost = parseFloat(i.cost)
    i.price = parseFloat(i.price)
    if (!i.displayOrder) {
      const displayOrders = proposal.proposalServiceItems.map(
        (i) => i.displayOrder || 0,
      )
      const maxDisplayOrder = Math.max(...displayOrders)
      i.displayOrder = maxDisplayOrder + 1
    }
  })
  sortByAttribute(proposal.proposalServiceItems, 'displayOrder')

  // Clean order virtual items
  proposal.proposalVirtualItems.forEach((i) => {
    i.cost = parseFloat(i.cost)
    i.price = parseFloat(i.price)
    if (!i.displayOrder) {
      const displayOrders = proposal.proposalVirtualItems.map(
        (i) => i.displayOrder || 0,
      )
      const maxDisplayOrder = Math.max(...displayOrders)
      i.displayOrder = maxDisplayOrder + 1
    }
  })
  sortByAttribute(proposal.proposalVirtualItems, 'displayOrder')

  // Group all order items by chef
  if (proposal.chefs.length > 0) {
    proposal.chefs.forEach((c) => {
      c.orderMenuItems = proposal.proposalMenuItems.filter(
        (i) => i.chefId === c.id,
      )
      c.orderServiceItems = proposal.proposalServiceItems.filter(
        (i) => i.chefId === c.id,
      )
      c.orderVirtualItems = proposal.proposalVirtualItems.filter(
        (i) => i.chefId === c.id,
      )
      c.orderVirtualKits = []
      c.orderSnackPacks = []
      c.customOrderMenuItems = []
      c.customOrderServiceItems = []
      c.customOrderVirtualItems = []
      c.chefNote = proposal.chefOrderInstructions.find((i) => i.chefId === c.id)
    })
  }

  const {
    accountSettings,
    clientSetUpTime,
    discountAmount,
    discountType,
    discounts,
    subtotal,
    tax,
    tip,
    serviceFee,
    total,
    taxRates,
    deliveryTaxRates,
    servicesTaxRates,
    alcoholTaxRates,
    dropoffAddress,
  } = proposal

  // calc initial balances
  const discountValue = calculateDiscounts({
    discountAmount,
    discountType,
    discounts,
    subtotal,
  })
  proposal.initialBalances = {
    subtotal,
    serviceFee,
    tax,
    tip,
    discounts: discountValue,
    total,
  }
  // calc taxRecord for tax calc in total section
  proposal.taxRecord = {
    tax,
    zipcode: dropoffAddress ? dropoffAddress.zip : undefined,
    taxRates,
    deliveryTaxRates,
    servicesTaxRates,
    alcoholTaxRates,
  }
  // apply default account settings
  const {
    deliveryAndServiceFeeCapHoliday,
    deliveryAndServiceFeeCapWeekday,
    deliveryAndServiceFeeCapWeekend,
    deliveryAndServiceFeePercentHoliday,
    deliveryAndServiceFeePercentWeekday,
    deliveryAndServiceFeePercentWeekend,
    gratuity,
    gratuityType,
  } = accountSettings || {}
  // calculate delivery fee balance calc settings
  const { percent: deliveryFeePercent, limit: deliveryFeeLimit } =
    getDeliveryFeePercentAndLimit({
      clientSetUpTime,
      deliveryAndServiceFeePercentHoliday,
      deliveryAndServiceFeePercentWeekend,
      deliveryAndServiceFeePercentWeekday,
      deliveryAndServiceFeeCapHoliday,
      deliveryAndServiceFeeCapWeekend,
      deliveryAndServiceFeeCapWeekday,
    })
  proposal.deliveryFeePercent = deliveryFeePercent
  proposal.deliveryFeeLimit = deliveryFeeLimit
  if (gratuity && gratuityType === DEFAULT_GRATUITY_TYPE_PERCENT) {
    proposal.defaultTipPercent = gratuity
  }

  return proposal
}

export const pRequestUpdateProposal = (data) => {
  const req = { ...snakeCaseify(data.taxRates) }

  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'account.id', 'account_id')
  formAdd(data, req, 'accountExecutive.id', 'account_executive_id')
  formAdd(data, req, 'conceptId', 'concept_id')
  formAdd(data, req, 'dinerProfileId', 'diner_profile_id')
  formAdd(data, req, 'dropoffAddress.id', 'dropoff_address_id')
  formAdd(data, req, 'paymentMethod.id', 'payment_method_id')
  formAdd(data, req, 'hubspotProposalId', 'existing_hubspot_id')
  // special consideration for payment method to be removed to avoid charging wrong client
  req.payment_method_id = (data.paymentMethod && data.paymentMethod.id) || null

  formAdd(data, req, 'cateringContact.id', 'contact_id')
  formAdd(data, req, 'invoiceContact.id', 'invoice_contact_id')
  formAdd(data, req, 'receiptContact.id', 'receipt_contact_id')
  formAdd(data, req, 'clientSetUpTime', 'client_set_up_time', (v) => v.toDate())
  formAdd(
    data,
    req,
    'clientDoNotArriveBeforeTime',
    'client_do_not_arrive_before_time',
    (v) => (v ? combineDateTime(data.clientSetUpTime, v) : null),
  )
  formAdd(data, req, 'clientArrivalTime', 'client_arrival_time', (v) =>
    combineDateTime(data.clientSetUpTime, v),
  )
  formAdd(data, req, 'hqArrivalTime', 'hq_arrival_time', (v) =>
    combineDateTime(data.clientSetUpTime, v),
  )
  formAdd(data, req, 'hqDepartureTime', 'hq_departure_time', (v) =>
    combineDateTime(data.clientSetUpTime, v),
  )
  formAdd(data, req, 'kitchenArrivalTime', 'kitchen_arrival_time', (v) =>
    combineDateTime(data.clientSetUpTime, v),
  )
  formAdd(data, req, 'kitchenDepartureTime', 'kitchen_departure_time', (v) =>
    combineDateTime(data.clientSetUpTime, v),
  )
  formAdd(data, req, 'serviceNotes', 'services_instructions')
  formAdd(data, req, 'accountSettings', 'account_settings', (v) =>
    snakeCaseify(v),
  )
  formAdd(data, req, 'deliveryInstructions', 'delivery_instructions')
  formAdd(data, req, 'orderType', 'order_type')
  formAdd(data, req, 'isEvent', 'is_event')
  formAdd(data, req, 'setUpStyle', 'set_up_style')
  formAdd(data, req, 'serviceType', 'service_type')
  formAdd(data, req, 'headCount', 'head_count')
  formAdd(data, req, 'purchaseOrderNumber', 'purchase_order_number')
  formAdd(data, req, 'predictedServiceCosts', 'predicted_service_costs', (v) =>
    v.toFixed(2),
  )
  formAdd(data, req, 'serviceFee', 'service_fee', (v) => v && v.toFixed(2))
  formAdd(
    data,
    req,
    'deliveryFee',
    'delivery_fee',
    (v) => (v && v.toFixed(2)) || 0,
  )
  formAdd(data, req, 'deliveryFeeLimit', 'delivery_fee_limit', (v) =>
    v ? v.toFixed(2) : null,
  )
  formAdd(data, req, 'deliveryFeePercent', 'delivery_fee_percent', (v) =>
    v ? v.toFixed(2) : null,
  )
  formAdd(
    data,
    req,
    'cleanupFee',
    'cleanup_fee',
    (v) => (v && v.toFixed(2)) || 0,
  )
  formAdd(
    data,
    req,
    'staffingFee',
    'staffing_fee',
    (v) => (v && v.toFixed(2)) || 0,
  )
  formAdd(data, req, 'numberOfStaff', 'number_of_staff', (v) =>
    (v || 0).toFixed(2),
  )
  formAdd(data, req, 'staffingHours', 'staffing_hours', (v) =>
    (v || 0).toFixed(2),
  )
  formAdd(data, req, 'staffingRate', 'staffing_rate', (v) =>
    (v || 0).toFixed(2),
  )
  formAdd(data, req, 'carbonNeutral', 'carbon_neutral')
  formAdd(
    data,
    req,
    'carbonNeutralContribution',
    'carbon_neutral_contribution',
    (v) => (v || 0).toFixed(2),
  )
  formAdd(data, req, 'isDeliveryFeeOverride', 'is_delivery_fee_override')
  formAdd(data, req, 'needsCleanup', 'needs_cleanup')
  formAdd(data, req, 'needsStaffing', 'needs_staffing')
  formAdd(data, req, 'chefAmount', 'chef_amount', (v) => v.toFixed(2))
  formAdd(data, req, 'subtotal', 'subtotal', (v) => v.toFixed(2)) // before delivery, tax, discounts => just order and service items
  formAdd(data, req, 'tax', 'tax', (v) => v.toFixed(2))
  formAdd(data, req, 'taxRates', 'services_tax_rates')
  formAdd(data, req, 'taxRates', 'delivery_tax_rates')
  formAdd(data, req, 'taxRates', 'tax_rates')
  formAdd(data, req, 'alcoholTaxRates', 'alcohol_tax_rates')
  req.tip = (data.tip || 0).toFixed(2)
  formAdd(data, req, 'total', 'total', (v) => v.toFixed(2))
  req.serving_utensil_sets = 1

  if (data.discount && Object.keys(data.discount).length > 0) {
    const is_percentage = data.discount.type === 'percent'
    const discount = {
      is_percentage,
      pre_total: false,
      reason: data.discount.reason,
      kind: data.discount.kind,
      value: is_percentage ? data.discount.amount / 100 : data.discount.amount,
    }
    if (data.discount.id) {
      discount.id = data.discount.id
    }
    if (data.discount._destroy) {
      discount._destroy = true
    }
    req.order_discounts_attributes = [discount]
  }

  if (data.discounts) {
    if (!req.order_discounts_attributes) {
      req.order_discounts_attributes = []
    }
    data.discounts.forEach((discount) => {
      if (discount._destroy) {
        req.order_discounts_attributes.push({ id: discount.id, _destroy: true })
      }
    })
  }

  /* Items */

  req.proposal_menu_items_attributes = []
  req.proposal_service_items_attributes = []
  req.proposal_virtual_items_attributes = []

  const displayOrder = [0] // array for pass by reference hack
  const addOrderMenuItemToReq = (orderMenuItem) => {
    req.proposal_menu_items_attributes.push(
      ...createOrderMenuItems(
        orderMenuItem,
        displayOrder,
        [],
        false,
        data.creatorId,
      ),
    )
  }
  const addOrderServiceItemToReq = (orderServiceItem) => {
    req.proposal_service_items_attributes.push(
      createOrderServiceItem(orderServiceItem, displayOrder),
    )
  }
  const addOrderVirtualItemToReq = (orderVirtualItem) => {
    req.proposal_virtual_items_attributes.push(
      createOrderVirtualItem(orderVirtualItem, displayOrder),
    )
  }

  data.chefs.forEach((chef) => {
    chef.orderMenuItems.forEach(addOrderMenuItemToReq)
    chef.customOrderMenuItems.forEach(addOrderMenuItemToReq)
    chef.orderServiceItems.forEach(addOrderServiceItemToReq)
    chef.customOrderServiceItems.forEach(addOrderServiceItemToReq)
    chef.orderVirtualItems.forEach(addOrderVirtualItemToReq)
    chef.customOrderVirtualItems.forEach(addOrderVirtualItemToReq)
  })

  // find deleted items
  const originalMenuItemIds = data.originalMenuItemIds.slice()
  const originalServiceItemIds = data.originalServiceItemIds.slice()
  const originalVirtualItemIds = data.originalVirtualItemIds.slice()
  const removePresentIds = (originalItemIds) => (item) => {
    if (item.id) {
      const idx = originalItemIds.indexOf(item.id)
      if (idx !== -1) {
        originalItemIds.splice(idx, 1)
      }
    }
  }
  req.proposal_menu_items_attributes.forEach(
    removePresentIds(originalMenuItemIds),
  )
  req.proposal_service_items_attributes.forEach(
    removePresentIds(originalServiceItemIds),
  )
  req.proposal_virtual_items_attributes.forEach(
    removePresentIds(originalVirtualItemIds),
  )
  originalMenuItemIds.forEach((id) => {
    req.proposal_menu_items_attributes.push({ id, _destroy: true })
  })
  originalServiceItemIds.forEach((id) => {
    req.proposal_service_items_attributes.push({ id, _destroy: true })
  })
  originalVirtualItemIds.forEach((id) => {
    req.proposal_virtual_items_attributes.push({ id, _destroy: true })
  })

  const chefNotes = []
  data.chefs.forEach((chef) => {
    if (chef.chefNote) {
      chefNotes.push(
        createChefOrderInstruction(chef.chefNote, 'Proposal', data.id),
      )
    }
  })
  req.chef_order_instructions_attributes = chefNotes

  req.order_service_cost_attributes = snakeCaseify(data.orderServiceCost)

  return req
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BiPencil } from 'react-icons/bi'
import { AuthorizedInteractable } from '@containers/common/auth'
import AccountProfileListGroup from './AccountProfileListGroup'
import { ColumnContainer, ButtonsBar } from '@res/styledComponents/index'

export class AccountProfile extends Component {
  state = {}

  componentDidMount() {
    const { account } = this.props
    this.loadAccountStats(account.id)
    this.loadDinerProfiles(account)
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.account &&
      this.props.account &&
      nextProps.account.id !== this.props.account.id
    ) {
      this.loadAccountStats(nextProps.account.id)
      this.loadDinerProfiles(nextProps.account)
    }
  }

  onEditAccount = (e) => {
    e.preventDefault()

    this.props.editAccount()
  }

  loadAccountStats = async (accountId) => {
    const stats = await this.props.loadAccountStats(accountId)
    if (stats) {
      this.setState({ stats })
    }
  }

  loadDinerProfiles = async (account) => {
    const { id, contacts, addresses } = account

    await this.props.loadDinerProfiles(id, contacts, addresses)
  }

  onEditDinerProfile = (dinerProfile) => {
    const { editDinerProfile } = this.props
    editDinerProfile(dinerProfile)
  }

  onDeleteDinerProfile = async (dinerProfile) => {
    const { deleteDinerProfile, account } = this.props

    const success = await deleteDinerProfile(dinerProfile)
    if (success) {
      this.loadDinerProfiles(account)
    }
  }

  onMarkDefaultDinerProfile = async (profileId) => {
    const { markDefaultDinerProfile, account, currentUser } = this.props

    const updatedBy = `${currentUser.firstName} ${currentUser.lastName}`

    await markDefaultDinerProfile(
      account.id,
      profileId,
      updatedBy,
      account.contacts,
      account.addresses,
    )
  }

  onMarkDefaultAccountContact = async (contactId) => {
    const {
      account: { id, accountContacts },
      markDefaultAccountContact,
    } = this.props

    const accountContact = accountContacts.find(
      (c) => c.contactId === contactId,
    )
    if (accountContact) {
      await markDefaultAccountContact(id, accountContact.id)
    }
  }

  onMarkDefaultAccountAddress = async (addressId) => {
    const {
      account: { id, addresses },
      markDefaultAccountAddress,
    } = this.props

    const address = addresses.find((a) => a.id === addressId)
    if (address) {
      await markDefaultAccountAddress(id, address.id)
    }
  }

  onDeleteAccountContact = async (accountContact) => {
    if (!accountContact) {
      return
    }
    await this.props.deleteAccountContact(accountContact)
  }

  onDeleteAccountAddress = async (address) => {
    await this.props.deleteAccountAddress(address)
  }

  render() {
    const { account, dinerProfiles } = this.props
    const { stats } = this.state

    return (
      <div>
        <ButtonsBar>
          <AuthorizedInteractable
            roles={['master admin', 'sales rep', 'sales lead']}
          >
            <button onClick={this.onEditAccount}>
              <BiPencil />
              Edit
            </button>
          </AuthorizedInteractable>
        </ButtonsBar>
        <ColumnContainer>
          <AccountProfileListGroup
            accountProfile={{ ...account, ...stats, dinerProfiles }}
            onEditAccount={this.onEditAccount}
            onEditDinerProfile={this.onEditDinerProfile}
            onDeleteDinerProfile={this.onDeleteDinerProfile}
            onMarkDefaultDinerProfile={this.onMarkDefaultDinerProfile}
            onMarkDefaultContact={this.onMarkDefaultAccountContact}
            onMarkDefaultAddress={this.onMarkDefaultAccountAddress}
            onDeleteAccountContact={this.onDeleteAccountContact}
            onDeleteAccountAddress={this.onDeleteAccountAddress}
          />
        </ColumnContainer>
      </div>
    )
  }
}

AccountProfile.propTypes = {
  account: PropTypes.object,
  currentUser: PropTypes.object,
  dinerProfiles: PropTypes.array,

  editAccount: PropTypes.func,
  loadAccountStats: PropTypes.func,
  loadDinerProfiles: PropTypes.func,
  editDinerProfile: PropTypes.func,
  deleteDinerProfile: PropTypes.func,
  markDefaultDinerProfile: PropTypes.func,
  markDefaultAccountContact: PropTypes.func,
  markDefaultAccountAddress: PropTypes.func,
  deleteAccountContact: PropTypes.func,
  deleteAccountAddress: PropTypes.func,
}

export default AccountProfile
